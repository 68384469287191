import React, { useState, useEffect } from "react";
import Layout from "../layout/Layout";
import AccountPageNavigation from "../components/account/AccountPageNavigation";
import useGetCustomerAddresses from "../hooks/customer/useGetCustomerAddresses";
import { formatName } from "../utils/components";
import IconPlus from "../assets/icon_js/IconPlus";
import { staticPage } from "../../config/staticPage";
import { getCustomerId } from "../utils/auth";
import { getUser } from "../utils/auth";

import { navigate } from "gatsby";
import Breadcrumbs from "../components/common/breadcrumbs/Breadcrumbs";
import useGetRepresentativesDetails from "../hooks/representatives-details/useGetRepresentativesDetails";
import RepresentativeNavigation from "../components/account/RepresentativeNavigation";
import Loader from "../components/form/Loader";
import DeleteConfirmationModal from "../components/form/Dialog/DeleteConfirmationModal";
import useDeleteCustomerAddress from "../hooks/customer/useDeleteCustomerAddress";

const AddressesPage = ({ location }) => {
  const { details: representative } = useGetRepresentativesDetails();
  const [address, setAddress] = useState([]);
  const user = getUser();
  const customer = getCustomerId();
  const [loading, setLoading] = useState(true);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [addressIdToDelete, setAddressIdToDelete] = useState(null);
  const { addresses, mutate: mutateAddresses } = useGetCustomerAddresses(
    user,
    customer
  );
  const { deleteCustomerAddress, isLoading } =
    useDeleteCustomerAddress(mutateAddresses);

  const getUserAddress = async () => {
    try {
      if (addresses) {
        setAddress(addresses);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserAddress();
  }, []);

  const handleDelete = (addressId) => {
    setAddressIdToDelete(addressId);
    setDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  return (
    <>
      <div className="page-wrapper account-page-wrapper">
        <div className="container">
          <Breadcrumbs location={location} />
        </div>

        <div className="container">
          <div className="account-content">
            <div className="row flex flex-wrap page-layout">
              <div className="col page-sidebar">
                <div className="page-sidebar-inner">
                  <AccountPageNavigation path={"/addresses"} />
                  {representative && (
                    <RepresentativeNavigation representative={representative} />
                  )}{" "}
                </div>
              </div>
              <div className="col page-content">
                <div className="page-content-inner">
                  <div className="page-heading-section">
                    <h1 className="page-title">Addresses</h1>
                  </div>

                  {isLoading || loading ? (
                    <>
                      <Loader />
                    </>
                  ) : (
                    <ul className="row flex flex-wrap address-list">
                      {addresses?.length &&
                        addresses?.map((address) => {
                          const {
                            id,
                            city,
                            state_or_province,
                            first_name,
                            last_name,
                            company,
                            address1,
                            address2,
                            country,
                            phone,
                            postal_code,
                            business_hours,
                          } = address;
                          return (
                            <li className="col address-item" key={id}>
                              <div className="adddress-item-inner">
                                <h6 className="address-title">
                                  {formatName(first_name, last_name)}
                                </h6>
                                <ul className="address-detail">
                                  <li>{company}</li>
                                  <li>{address1}</li>
                                  <li>{address2}</li>
                                  <li>{`${city}, ${state_or_province}, ${postal_code}`}</li>
                                  <li>{country}</li>
                                  <li>
                                    <span>Phone: </span>
                                    {phone}
                                  </li>
                                </ul>
                                <div className="form-actions">
                                  <button
                                    type="submit"
                                    className="button button-small"
                                    onClick={() =>
                                      navigate(
                                        `/create-update-address?address_id=${id}&customer_id=${customer}`
                                      )
                                    }
                                  >
                                    Edit
                                  </button>
                                  <button
                                    type="submit"
                                    className="button button--secondary button-small"
                                    onClick={() => handleDelete(id)}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      <li className="col address-item add-address-link">
                        <div
                          className="adddress-item-inner"
                          onClick={() => navigate("/create-update-address")}
                        >
                          <i className="add-icon">
                            <IconPlus />
                          </i>
                          <p>New Address</p>
                        </div>
                      </li>
                    </ul>
                  )}
                </div>
              </div>

              <DeleteConfirmationModal
                isOpen={isDeleteModalOpen}
                handleClose={handleDeleteModalClose}
                addressId={addressIdToDelete}
                deleteCustomerAddress={deleteCustomerAddress}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const Head = () => {
  let login_nav = staticPage;
  let loginDetails = null;
  for (const obj of login_nav) {
    if (obj.name === "addresses") {
      loginDetails = obj;
      break; // Exit the loop once the desired object is found.
    }
  }
  return (
    <>
      <title>{loginDetails.title}</title>
      <meta name="description" content={loginDetails.description} />
    </>
  );
};

AddressesPage.Layout = Layout
export default AddressesPage;
