import useSWR from "swr";
import Api from "../../services/Api";

export default function useGetCustomerAddresses(user, customer) {
  const getKey = () =>
    user?.customer_email ? `/customer/address?customer_id=${customer}` : null;

  const fetcher = async (url) => {
    const response = await Api.get(url);
    return response.data.data;
  };

  const { data:addresses, error ,mutate } = useSWR(getKey(), fetcher);

  return {
    addresses,
    error,
    mutate
  };
}
