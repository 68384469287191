import { useState } from "react";
import Api from "../../services/Api";
import { getUser } from "../../utils/auth";
import { navigate } from "gatsby";

function useDeleteCustomerAddress(mutateAddresses) {
  const [isLoading, setLoading] = useState(false);
  const user = getUser();
  const deleteCustomerAddress = async (address_id) => {
    const url = user?.customer_email
      ? `/customer/address?id=${address_id}`
      : null;
    try {
      setLoading(true);
      await deleteAddress(url);
      mutateAddresses()
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return {
    deleteCustomerAddress,
    isLoading,
  };
}

async function deleteAddress(url) {
  const response = await Api.delete(url);
  return response.data.data;
}

export default useDeleteCustomerAddress;
