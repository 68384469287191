import React from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";

import IconClose from "../../../assets/icon_js/IconClose";

Modal.setAppElement("body");

const DialogHead = ({ children }) => {
  return <div className="modal-title">{children}</div>;
};

function DeleteConfirmationModal({
  deleteCustomerAddress,
  isOpen,
  handleClose,
  addressId,
}) {
  function confirmDelete() {
    deleteCustomerAddress(addressId);
    handleClose();
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose}>
      <div className="modal-outer">
        <div className="modal-inner">
          <div className="modal-body-outer">
            <button
              type="button"
              className="modal-close-button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <IconClose />
            </button>

            <div className="modal-header">
              <DialogHead>Confirm Delete</DialogHead>
            </div>

            <div className="modal-body">
              <p>Are you sure you want to delete this address?</p>
            </div>

            <div className="modal-footer flex justify-space ">
              <button
                type="button"
                className="button button-small"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                type="button"
                className="button button--secondary button-small"
                onClick={confirmDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

DeleteConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
  addressId: PropTypes.string,
  showCloseButton: PropTypes.bool,
};

export default DeleteConfirmationModal;
